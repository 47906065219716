.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #{$primary-color};
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #{$primary-color};
    --mat-tab-header-active-ripple-color: #{$primary-color};
    --mat-tab-header-inactive-ripple-color: #{$primary-color};
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #{$primary-color};
    --mat-tab-header-active-hover-label-text-color: #{$primary-color};
    --mat-tab-header-active-focus-indicator-color: #{$primary-color};
    --mat-tab-header-active-hover-indicator-color: #{$primary-color};
}

.mat-mdc-tab-header {
    --mat-tab-header-label-text-letter-spacing: 0.05em;
}
