@use '@angular/material' as mat;
@use 'sass:map';

// .mat-flat-button.mat-accent[disabled],
// .mat-flat-button.mat-warn[disabled],
// .mat-flat-button[disabled],
// .mat-raised-button.mat-accent[disabled],
// .mat-raised-button.mat-warn[disabled],
// .mat-raised-button[disabled],
// .mat-fab.mat-accent[disabled],
// .mat-fab.mat-warn[disabled],
// .mat-fab[disabled],
// .mat-mini-fab.mat-accent[disabled],
// .mat-mini-fab.mat-warn[disabled],
// .mat-mini-fab[disabled] {
//     background-color: #A7A7A7;
//     color: #FFFFFF;
// }

.mat-flat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-fab.mat-secondary,
.mat-mini-fab.mat-secondary {
    background-color: #471b46;
    color: #ffffff;

    &[disabled] {
        background-color: #471b46;
        color: #ffffff;
        opacity: 0.6;
    }
}

@mixin update-disabled-bg-color($bg-color, $text-color) {
    &[disabled] {
        background-color: $bg-color;
        opacity: 0.6;
        color: $text-color;
    }

    background-color: $bg-color;
    opacity: 0.6;
    color: $text-color;
}

@mixin button($theme, $theme-name) {
    $accent-color: mat.m2-get-color-from-palette(map-get($theme, 'accent'));
    $primary-color: mat.m2-get-color-from-palette(map-get($theme, 'primary'));
    $warn-color: mat.m2-get-color-from-palette(map-get($theme, 'warn'));
    $brand-color: mat.m2-get-color-from-palette($wise-pelican-purple, 500);

    @include button-styles($theme-name);
    color: #ffffff;

    &.small {
        padding: 5px 10px;
    }

    .mat-button-focus-overlay {
        background-color: #000 !important;
    }

    &.mat-flat-button {
        @include mat.elevation-transition;

        &:not([disabled]):hover {
            @include mat.elevation(4);
        }
    }

    &.mat-primary {
        background-color: $primary-color;
    }

    &.mat-accent {
        background-color: $accent-color;
        // color: mat.get-color-from-palette(map-get($theme, 'accent', 'contrast'), 'main');
    }

    &.mat-warn {
        background-color: $warn-color;
    }

    @include bmd-disabled() {
        &[disabled] {
            background-color: $silver-chalice;
            color: #ffffff;
        }

        background-color: $silver-chalice;
        color: #ffffff;

        &.mat-accent {
            @include update-disabled-bg-color($accent-color, #ffffff);
        }

        &.mat-primary {
            $primary: map-get($theme, 'primary');

            @include update-disabled-bg-color($primary-color, #ffffff);
        }

        &.mat-warn {
            $warn: map-get($theme, 'warn');

            @include update-disabled-bg-color($warn-color, #ffffff);
        }

        &.brand {
            @include update-disabled-bg-color($brand-color, #ffffff);
        }
    }

    &.brand {
        background-color: $brand-color;
    }

    &.wp-icon-button {
        // .mat-button-wrapper {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }

        .mat-icon {
            font-size: inherit;
            height: min-content;
            width: min-content;
        }
    }
}

.mat-button-base {
    &.wp {
        // @include button($custom-theme, "2022")
    }

    &.wp-2023 {
        // @include button($custom-theme-2023, "2023")
    }
}

.mdc-button {
    // --mdc-typography-button-letter-spacing: 0;
    --mdc-filled-button-label-text-tracking: 0;
    --mdc-text-button-label-text-tracking: 0;

    --mdc-outlined-button-label-text-tracking: 0;
    line-height: 1rem !important;
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-mini-fab {
    --mdc-filled-button-container-color: transparent;
    --mdc-fab-container-color: #{$default-color};
    --mat-mdc-fab-color: white;

    &[disabled][disabled] {
        opacity: 0.6;
    }

    &.mat-primary {
        --mdc-filled-button-container-color: #{$primary-action-color};
        --mdc-fab-container-color: #{$primary-action-color};
        --mdc-text-button-label-text-color: #{$default-color};

        &[disabled][disabled] {
            --mdc-filled-button-disabled-container-color: #{$primary-action-color};
            --mdc-filled-button-disabled-label-text-color: #fff;
        }
    }

    &.mat-accent {
        --mdc-filled-button-container-color: #{$accent-color};
        --mdc-fab-container-color: #{$primary-action-color};

        &[disabled][disabled] {
            --mdc-filled-button-disabled-container-color: #{$accent-color};
            --mdc-filled-button-disabled-label-text-color: #fff;
        }
    }

    &.mat-warn {
        &[disabled][disabled] {
            --mdc-filled-button-disabled-container-color: #{$warn-color};
            --mdc-filled-button-disabled-label-text-color: #fff;
        }
    }
}

.mat-mdc-outlined-button {
    &.mat-primary {
        --mdc-outlined-button-label-text-color: #{$primary-color};
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    padding: 4px;
    --mdc-icon-button-state-layer-size: 32px;

    > mat-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;

        &.mat-icon-small {
            width: 16px;
            height: 16px;
            font-size: 16px;
            // --mdc-icon-button-state-layer-size: 16px
        }
    }

    > .mat-mdc-button-touch-target {
        width: 24px;
        height: 24px;
    }

    &.mat-primary {
        --mdc-icon-button-icon-color: #{$primary-color};
        --mat-mdc-button-persistent-ripple-color: #{$primary-color};
    }
}
