@use '@angular/material' as mat;

.mat-slide-toggle.wp {
    $brand-color: mat.m2-get-color-from-palette($wise-pelican-purple, 500);

    &.brand {
        &.mat-checked {
            .mat-slide-toggle-thumb {
                background-color: $brand-color;
            }

            .mat-slide-toggle-bar {
                background-color: rgba($brand-color, 0.54);
            }

            .mat-ripple-element {
                background-color: $brand-color;
            }
        }
    }
}

.mat-mdc-slide-toggle {
    &.mat-primary {
        --mdc-switch-selected-focus-state-layer-color: #{$primary-color};
        --mdc-switch-selected-handle-color: #{$primary-color};
        --mdc-switch-selected-hover-state-layer-color: #{$primary-color};
        --mdc-switch-selected-pressed-state-layer-color: #{$primary-color};
        // Darker
        --mdc-switch-selected-focus-handle-color: #{$primary-color};
        --mdc-switch-selected-hover-handle-color: #{$primary-color};
        --mdc-switch-selected-pressed-handle-color: #{$primary-color};
        //Lighter
        --mdc-switch-selected-focus-track-color: #{$primary-color};
        --mdc-switch-selected-hover-track-color: #{$primary-color};
        --mdc-switch-selected-pressed-track-color: #{$primary-color};
        --mdc-switch-selected-track-color: #{$primary-color};
    }
}
