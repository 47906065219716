@use '@angular/material' as mat;

.mat-card.wp {
    width: 100%;

    .mat-card-header {
        margin-bottom: 16px;

        .mat-card-header-text {
            margin-top: 4px;
        }

        .mat-icon {
            width: 66px;
            height: 66px;
            background-color: mat.m2-get-color-from-palette($primary);
            color: $white;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 5%;
        }

        .mat-card-title {
            color: $mine-shaft;
            font-size: 25px;
            font-weight: bold;
            padding-top: 3px;
        }

        .mat-card-subtitle {
            font-size: 18px;
            padding-top: 3px;
            margin-bottom: 0;
            font-weight: 500;
            color: $mine-shaft;
        }
    }

    .mat-card-content {
        @media (min-width: 992px) {
            margin: 0 81px;
        }
    }

    .mat-card-actions,
    .mat-card-actions:last-child {
        margin: 0;

        @media (min-width: 992px) {
            margin: 0 81px;
        }
    }

    &.no-content-margin {
        .mat-card-content,
        .mat-card-actions,
        .mat-card-actions:last-child {
            margin: 0;
        }
    }
}

.mat-mdc-card {
    > .mat-mdc-card-header {
        .mat-icon {
            width: 52px;
            height: 52px;
            background-color: $wp-brand-dark-purple;
            color: $white;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 4px;
        }

        .mat-mdc-card-header-text {
            .mat-mdc-card-title {
                color: $mine-shaft;
                font-size: 20px;
                font-weight: bold;
                padding-top: 3px;
                padding-bottom: 3px;
            }

            .mat-mdc-card-subtitle {
                font-size: 16px;
                padding-top: 0px;
                margin-bottom: 12px;
                font-weight: 500;
                color: $mine-shaft;
            }
        }
    }

    > .mat-mdc-card-actions {
        &.one-action {
            justify-content: flex-end;
        }
    }
}
