.mat-mdc-slider {
    &.mat-primary {
        --mdc-slider-handle-color: #{$primary-color};
        --mdc-slider-focus-handle-color: #{$primary-color};
        --mdc-slider-hover-handle-color: #{$primary-color};
        --mdc-slider-active-track-color: #{$primary-color};
        --mdc-slider-inactive-track-color: #{$primary-color};
        --mdc-slider-with-tick-marks-inactive-container-color: #{$primary-color};
        --mat-mdc-slider-ripple-color: #{$primary-color};
    }
}
