// TODO import colors - for some reason does not work
.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #471b46;

    &.error {
        --mdc-snackbar-container-color: #be0000;
    }

    &.warn {
        --mdc-snackbar-container-color: #fd6500;
    }

    .mat-mdc-simple-snack-bar {
        text-align: center;
    }
}
