html {
    --mdc-filled-text-field-container-color: white;
    --mat-form-field-state-layer-color: white;

    --mat-form-field-container-text-size: 16px;
    --mdc-filled-text-field-label-text-size: 16px;
    @media (min-width: 600px) {
        --mat-form-field-container-text-size: 15px;
        --mdc-filled-text-field-label-text-size: 15px;
    }
}

.mat-form-field.wp,
.mat-form-field-appearance-legacy.wp {
    input::placeholder {
        color: $gray-2;
        text-transform: uppercase;
    }

    input {
        color: $mine-shaft;
    }

    &.ng-untouched {
        .mat-form-field-label {
            color: $tundora;
        }
    }

    &.ng-touched.ng-valid {
        .mat-form-field-label {
            color: $tundora;
        }
    }

    .mat-form-field-infix {
        border-top: 27.25px solid transparent;
        padding-bottom: 12.25px;
    }

    .mat-form-field-label-wrapper {
        top: -22px;
        font-size: 14px;

        .mat-form-field-label {
            // prevent font shrinking
            transform: translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.001px) !important;
            width: 100% !important;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        // fix blue background in when using autofill
        -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    }

    // &.pagination {
    //     .mat-form-field-infix {
    //         border-top: 17px solid transparent;
    //     }

    //     .mat-form-field-underline {
    //         bottom: 25px;
    //     }
    // }

    &.filter-search {
        border: 1px solid $dove-gray;
        border-radius: 5px;
        padding: 2px 10px;

        .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-infix {
            border-top: 11px solid transparent;
            padding-bottom: 0;
        }

        .mat-form-field-wrapper {
            padding-bottom: 2px;
        }

        .mat-form-field-label-wrapper {
            top: -9px;
            font-size: 11px;

            label.mat-form-field-label {
                color: $dusty-gray;
            }
        }

        .mat-select {
            color: $mine-shaft;
        }
    }

    .mat-form-field-required-marker {
        color: $wp-brand-purple;
    }
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
    padding-top: 12px !important;
}

.mat-mdc-form-field {
    // --mdc-typography-body1-letter-spacing: 0.33px;

    --mdc-typography-body1-font-size: 16px;
    --mdc-typography-subtitle1-font-size: 16px;
    // --mdc-typography-subtitle1-letter-spacing: 0.367px;

    // Really dirty and partially effective way to have 16px on iphones

    @media (min-width: 600px) {
        --mdc-typography-body1-font-size: 15px;
        --mdc-typography-subtitle1-font-size: 15px;
    }

    --mdc-typography-body1-font-weight: 400;
    // --mdc-typography-body1-line-height: 16px;
    // --mdc-shape-small: 0;
    --mdc-typography-caption-font-size: 11px;

    .mdc-text-field--filled .mdc-line-ripple {
        --mdc-theme-primary: #{$wp-brand-purple};
    }

    .mdc-text-field--outlined {
        --mdc-theme-primary: #{$wp-brand-purple};

        .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
            font-size: 14px;
        }
    }

    .mat-mdc-form-field-infix {
        padding-top: 24px !important;
        padding-bottom: 6px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        // --mdc-typography-caption-line-height: 4px;

        &.mat-mdc-form-field-bottom-align::before {
            height: 4px;
        }
    }

    .mat-mdc-floating-label {
        color: rgba(0, 0, 0, 0.75) !important;
    }
}

// .mat-mdc-option {
//     --mdc-typography-body1-font-size: 14px;
//     --mdc-theme-primary: black
// }

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: #{$primary-color};
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    --mdc-filled-text-field-focus-label-text-color: #{$primary-color};
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.87) !important;
}
