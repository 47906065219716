.mat-mdc-radio-button {
    &.mat-primary {
        --mdc-radio-selected-focus-icon-color: #{$primary-color};
        --mdc-radio-selected-hover-icon-color: #{$primary-color};
        --mdc-radio-selected-icon-color: #{$primary-color};
        --mdc-radio-selected-pressed-icon-color: #{$primary-color};
        --mat-radio-checked-ripple-color: #{$primary-color};
    }

    .mdc-label {
        margin-bottom: 0;
    }
}
