/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';

body,
html {
    height: 100%;
}

.mat-select-panel.wide {
    max-width: 1000px;
}

.cdk-overlay-container {
    z-index: 1050;
}

.cdk-overlay-connected-position-bounding-box {
    z-index: 1050;
}

.card.StripeElement {
    padding-left: 8px;
}

.btn.btn-primary {
    background-color: #471b46;
}

@font-face {
    font-family: sodosans-bold;
    src: url(./assets/font/SoDoSans_Bold.otf) format('opentype');
}

// @font-face {
//     font-family: sodosans-semi-bold;
//     src: url(./assets/font/SoDoSans_SemiBold.otf) format("opentype");
// }

@font-face {
    font-family: sodosans-regular;
    src: url(./assets/font/SoDoSans_Regular.otf) format('opentype');
}

// @font-face {
//     font-family: open-sans-bold;
//     src: url(./assets/font/OpenSans_Bold.ttf) format("truetype");
// }

@font-face {
    font-family: museosans-900;
    src: url(./assets/font/MuseoSans_900.otf) format('opentype');
}

@font-face {
    font-family: museosans-700;
    src: url(./assets/font/MuseoSans_700.otf) format('opentype');
}

@font-face {
    font-family: museosans-500;
    src: url(./assets/font/MuseoSans_500.otf) format('opentype');
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mat-radio-label-content {
    white-space: normal;
    color: rgba(0, 0, 0, 0.8);
}

.mat-dialog-content {
    padding-bottom: 2px !important;
}

.wp-row {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.wp-col {
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
}

.wp-centered-content {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.image-dialog-container {
    border-radius: 16px;
    overflow: hidden;
    // width: 800px;
}

.wp-no-padding .mat-form-field-wrapper {
    // margin: 0 !important;
    // padding: 0;
    margin-bottom: -1.25em;
}

.centered-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.custom-dialog-container * > .mat-mdc-dialog-surface {
    border-radius: 8px !important;
}
