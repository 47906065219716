$tundora: #404040;
$mine-shaft: #3e3e3e;
$forest-green: #449429;
$gray: #939393;
$gray-2: #909090;
$silver-chalice: #a7a7a7;
$sunset-orange: #f55145;
$dove-gray: #707070;
$dusty-gray: #989898;

$wp-brand-purple: #471b46;
$wp-brand-teal: #5ac8c8;
$wp-brand-dark-purple: #4d1646;

$primary-action-color: #449429;
$primary-color: #471b46;
$accent-color: #4f4f4f;
$warn-color: #f44336;
$default-color: #471b46;
