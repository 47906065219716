.mat-checkbox.wp {
    label {
        color: $tundora;
    }

    .mat-checkbox-frame {
        border-color: #4a4a4a;
    }

    &.mat-checkbox-checked {
        .mat-checkbox-background {
            background-color: #5c505b;
        }
    }
}

mat-checkbox.mat-mdc-checkbox {
    --mdc-typography-body2-letter-spacing: normal;
    --mdc-checkbox-state-layer-size: 28px;

    &.mat-primary {
        --mdc-checkbox-selected-focus-icon-color: #4d1646;
        --mdc-checkbox-selected-hover-icon-color: #4d1646;
        --mdc-checkbox-selected-icon-color: #4d1646;
        --mdc-checkbox-selected-pressed-icon-color: #4d1646;
        --mdc-checkbox-selected-focus-state-layer-color: #4d1646;
        --mdc-checkbox-selected-hover-state-layer-color: #4d1646;
    }

    .mdc-label {
        margin-bottom: unset;
        color: rgba(0, 0, 0, 0.87);
        padding-left: 7px;
    }
}
