.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-size: 14px;

    .mat-mdc-dialog-actions {
        justify-content: space-between;

        &.one-action {
            justify-content: flex-end;
        }
    }
}

.loading-overlay .mat-dialog-container {
    background: $wp-brand-purple;
}

* {
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
    --mdc-dialog-supporting-text-tracking: 0;
}
