@use '@angular/material' as mat;

.mat-mdc-chip {
    --mdc-chip-label-text-size: 10px;
}

.mat-mdc-standard-chip {
    &.mat-primary {
        --mdc-chip-elevated-container-color: #{$primary-color};
        --mdc-chip-label-text-color: white;
    }

    &.mat-accent {
        --mdc-chip-elevated-container-color: #{$accent-color};
        --mdc-chip-label-text-color: white;
    }
}

// .mat-chip-list.wp {
//     .mat-chip {
//         font-size: 10px;
//         padding: 5px 12px;
//         text-transform: uppercase;
//         line-height: 1;
//         min-height: 20px;

//         &.mat-accent.mat-chip-selected {
//             $accent: map-get($custom-theme, 'accent');
//             // Use !important since the bgcolor is being set in material-dashboard.scss
//             background-color: mat.get-color-from-palette($accent) !important;
//         }

//         &.disable-pointer-events {
//             pointer-events: none;

//             &::after {
//                 display: none;
//             }
//         }
//     }
// }
