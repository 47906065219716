.loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    overflow: hidden;
}

.loading-animation {
    &::before {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
        width: 100%;
        height: 100%;
        top: 0;
        transform: translateX(-115%) skewX(-15deg);
        animation: shine 1.5s 0.25s infinite;
    }

    &.dark-mode::before {
        background: rgba(0, 0, 0, 0.05);
    }
}

@keyframes shine {
    from {
        transform: translateX(-115%);
        width: 0%;
    }

    to {
        transform: translateX(200%);
        width: 50%;
    }
}

.determinate-loading {
    background-color: rgba(255, 255, 255, 0.2);
    width: 0%;
    height: 100%;
    position: absolute;
}
