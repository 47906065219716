@mixin button-styles($theme-name) {
    size: 0.8rem;
    font-weight: 400;
    padding: 0.40625rem 1.25rem;
    line-height: 1.5;
    text-transform: uppercase;
    white-space: pre-wrap;

    @if $theme-name == '2023' {
        text-transform: none;
        padding: 0.8125rem 1.25rem;
        // line-height: 2.5;
    }
}
