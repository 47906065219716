.mat-button-toggle-group.wp {
    .mat-button-toggle {
        &.mat-button-toggle-checked {
            background-color: $wp-brand-purple;
            color: #fff;

            .mat-button-toggle-focus-overlay {
                background-color: #fff;
            }
        }

        .mat-button-toggle-button {
            @include button-styles('2022');
        }

        .mat-button-toggle-label-content {
            line-height: 1.5;
        }
    }
}
