html {
    --mat-option-label-text-size: 14px;
    --mat-option-selected-state-label-text-color: black;
    --mat-select-trigger-text-tracking: 0.33px;

    --mat-select-trigger-text-size: 16px;
    @media (min-width: 600px) {
        --mat-select-trigger-text-size: 15px;
    }
}
