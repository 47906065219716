.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

.main-panel {
    position: relative;
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content {
        padding: 30px 15px;
        $nav-bar-height: 50px;
        min-height: calc(100vh - $nav-bar-height - 1px);

        @media (max-width: 991px) {
            padding: 30px 0;
        }
    }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
    display: inline-block !important;
}

.visible-on-sidebar-mini {
    display: none !important;
}

@media (min-width: 992px) {
    .sidebar-mini {
        .visible-on-sidebar-regular {
            display: none !important;
        }

        .visible-on-sidebar-mini {
            display: inline-block !important;
        }

        .sidebar,
        .sidebar .sidebar-wrapper {
            width: 80px;
        }

        .main-panel {
            width: $sidebar-mini-width;
        }

        .sidebar {
            display: block;
            font-weight: 200;
            z-index: 9999;

            .logo {
                a.logo-normal {
                    opacity: 0;
                    @include transform-translate-x(-25px);
                }
            }

            .sidebar-wrapper {
                > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span,
                > .nav li > a p {
                    @include transform-translate-x(-25px);
                    opacity: 0;
                }

                .nav-button {
                    > button {
                        min-width: unset;
                    }

                    .mat-icon {
                        display: inline;
                    }

                    .button-text {
                        display: none;
                    }
                }
            }
        }

        .sidebar:hover {
            width: 260px;

            .logo {
                a.logo-normal {
                    opacity: 1;
                    @include transform-translate-x(0px);
                }
            }

            .sidebar-wrapper {
                width: 260px;

                > .nav li > a p,
                > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .user-info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
                .user .user-info > a > span {
                    @include transform-translate-x(0px);
                    opacity: 1;
                }

                .nav-button {
                    > button {
                        .button-text {
                            display: inline;
                        }

                        .mat-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.hide-sidebar {
    .sidebar {
        @extend .animation-transition-general;

        @include transform-translate-x(-260px);
    }

    .main-panel {
        width: 100%;
    }

    &.sidebar-mini {
        .sidebar {
            @extend .animation-transition-general;

            @include transform-translate-x(-80px);
        }
    }
}

.animation {
    @extend .animation-transition-general;
}
